'use client'

import Link from 'next/link';
import ProductFinder from '@/images/icons/productfinder.svg';
import CustomerPortal from '@/images/icons/customerportal.svg';

interface CtaEyecatcherPlaceholderProps {
    bgGrey?: boolean;
}
interface CtaEyecatcherProps {
    elements: any[];
    withStartAnimation: boolean;
}

const EYECATCHER_HEIGHT_CLASS = 'h-14';

export default function CtaEyecatcher({elements, withStartAnimation}: CtaEyecatcherProps) {
    return (
        <div id="cta-eyecatcher"
             className={`z-10 sticky inset-x-0 bottom-0 grid grid-cols-2 -mt-14 w-full sm:w-auto sm:self-end ${EYECATCHER_HEIGHT_CLASS} ${withStartAnimation && 'animate-start-slide-up'}`}>
            {elements.filter(e => e.elements?.link?.elementContext?.data?.link?.path).map((el, index) => (
                <Link key={index}
                      href={el.elements.link.elementContext.data.link.path}
                      target={el.elements.link.elementContext.data.link.target}
                      title={el.elements.link.elementContext.data.link.title}
                      className="h-full w-full shrink items-center gap-2 border-l border-l-secondary-200 px-4 transition-colors ease-out bg-secondary-100 u-center first:border-l-0 hover:bg-secondary-200 sm:first:rounded-tl-lg">

                    {el.elements.icon.elementContext.data.icon === 'productfinder' && (
                        <ProductFinder className="h-6 w-6 shrink-0 object-contain object-center text-secondary-text"/>
                    )}
                    {el.elements.icon.elementContext.data.icon === 'customerportal' && (
                        <CustomerPortal className="h-6 w-6 shrink-0 object-contain object-center text-secondary-text"/>
                    )}

                    <span className="text-sm font-bold text-secondary-text truncate sm:overflow-visible">
                        {el.elements.text.elementContext.data.text}
                    </span>
                </Link>
            ))}
        </div>
    )
}

export function CtaEyecatcherPlaceholder({ bgGrey = false }: CtaEyecatcherPlaceholderProps) {
    return (
        <div className={`w-full ${EYECATCHER_HEIGHT_CLASS} ${bgGrey && 'bg-grey-100'}`}></div>
    )
}
